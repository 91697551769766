import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ "../views/Login")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: "contact" */ "../views/Contact")
);
const Location = lazy(() =>
  import(/* webpackChunkName: "location" */ "../views/Location")
);
const Brand = lazy(() =>
  import(/* webpackChunkName: "brand" */ "../views/Brand")
);
// const CategoryProduct = lazy(() =>
//   import(
//     /* webpackChunkName: "categoryProduct" */ "../views/Product/CategoryProduct"
//   )
// );
const Product = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product")
);
// const Service = lazy(() =>
//   import(/* webpackChunkName: "service" */ "../views/Service")
// );
const CategoryProject = lazy(() =>
  import(
    /* webpackChunkName: "categoryProject" */ "../views/Project/CategoryProject"
  )
);
const Project = lazy(() =>
  import(/* webpackChunkName: "project" */ "../views/Project")
);
const TableProject = lazy(() =>
  import(/* webpackChunkName: "project" */ "../views/Project/TableProject")
);
const AboutUs = lazy(() =>
  import(/* webpackChunkName: "project" */ "../views/AboutUs")
);
const Event = lazy(() =>
  import(/* webpackChunkName: "project" */ "../views/Event")
);
const News = lazy(() =>
  import(/* webpackChunkName: "project" */ "../views/News")
);
const Banner = lazy(() =>
  import(/* webpackChunkName: "news" */ "../views/Banner")
);
const Meta = lazy(() => import(/* webpackChunkName: "news" */ "../views/Meta"));

function Routes() {
  return (
    <main>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/signin" component={Login} />
          <AuthenticatedRoute path="/contact-us" component={Contact} />
          <AuthenticatedRoute path="/our-location" component={Location} />
          <AuthenticatedRoute path="/brand" component={Brand} />
          {/* <AuthenticatedRoute path="/product/category" component={CategoryProduct} /> */}
          <AuthenticatedRoute path="/service" component={Product} />
          {/* <AuthenticatedRoute path="/service" component={Service} /> */}
          <AuthenticatedRoute
            path="/project/category"
            component={CategoryProject}
          />
          <AuthenticatedRoute path="/project" component={Project} />
          <AuthenticatedRoute path="/table-project" component={TableProject} />
          <AuthenticatedRoute path="/about-us" component={AboutUs} />
          <AuthenticatedRoute path="/event" component={Event} />
          <AuthenticatedRoute path="/news" component={News} />
          <AuthenticatedRoute path="/banner" component={Banner} />
          <AuthenticatedRoute path="/meta-tag" component={Meta} />
          <Redirect from="/" to="/signin" exact />
        </Switch>
      </Suspense>
    </main>
  );
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get("BPWTKNCMS") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default Routes;
